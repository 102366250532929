import React from 'react'

import SEO from '../../components/seo'
import Responsible from '../../components/ResponsibleGaming/ResponsibleGaming'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { RESPONSIBLE_GAMING as RESPONSIBLE_GAMING_LINK } from '../../components/internal-links'
import { generateBreadcrumbsSchema } from '../../lib/generate-schema'

const breadcrumbs: Breadcrumb[] = [
  {
    title: 'Homepage',
    url: '/',
  },
  {
    title: 'Responsible Gaming',
    url: RESPONSIBLE_GAMING_LINK,
  },
]

const ResponsibleGaming: React.FC = () => {
  return (
    <div>
      <SEO
        title="Resposible Gaming: Read How to Play Real Money Gaming Responsibly"
        description="Read how to play responsibly on MegaRummy. MegaRummy is an entertainment platform that encourages all its users to play, learn, earn, and have fun. Click to read more!"
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Responsible />
    </div>
  )
}

export default ResponsibleGaming
